import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import theme from "shared/lib/designSystem/theme";
import colors from "shared/lib/designSystem/colors";
import { Subtitle, Title } from "shared/lib/designSystem";
import useTVL from "shared/lib/hooks/useTVL";
import Pagination from "shared/lib/components/Common/Pagination";
import { formatAmount } from "shared/lib/utils/math";
import useElementSize from "shared/lib/hooks/useElementSize";
import { getVaultColor } from "shared/lib/utils/vault";
import OverviewBarchart from "./OverviewBarchart";
import useLoadingText from "shared/lib/hooks/useLoadingText";

const perPage = 3;

const SectionLabel = styled.div`
  display: flex;
  padding: 16px;
  background: ${colors.red}1F;
  border-radius: ${theme.border.radiusSmall};
`;

const TVLLeaderboard = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { width: containerWidth } = useElementSize(containerRef);
  const { data, totalTVL, loading } = useTVL();
  const loadingText = useLoadingText();

  const [page, setPage] = useState(1);

  return (
    <div
    ref={containerRef}
    className="d-flex flex-column w-100 align-items-center"
  >
    <SectionLabel>
      <Subtitle
        color={colors.red}
        fontSize={11}
        lineHeight={12}
        letterSpacing={1}
      >
        DAO Revenue History
      </Subtitle>
    </SectionLabel>
    <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://rows.com/embed/1gheZ0uQVPQUXhBYTGh9wJcOc5NLkySxGw6Q4Ghy1de4/48a3224c-8a90-416e-80c8-500ab1a745ff' class='rows-embed' frameborder='0' width='550' height='450' style='max-width: 100%; border: 0px solid #EAEAEA; border-radius: 4px; margin-top:20px;'></iframe>"}} />
    </div>
  );
};

export default TVLLeaderboard;
