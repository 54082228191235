import React from "react";

import Logo from "shared/lib/assets/icons/logo";
import { ActionButton } from "shared/lib/components/Common/buttons";
import {
  BaseUnderlineLink,
  BaseModalContentColumn,
  SecondaryText,
  Title,
} from "shared/lib/designSystem";
import colors from "shared/lib/designSystem/colors";
import { useRBNTokenAccount } from "shared/lib/hooks/useRBNTokenSubgraph";
import { formatBigNumber } from "shared/lib/utils/math";
import useTokenBalance from "shared/lib/hooks/useTokenBalance";
import { BigNumber } from "@ethersproject/bignumber";

interface StakingModalApproveProps {
  onApprove: () => void;
}

const StakingModalApprove: React.FC<StakingModalApproveProps> = ({
  onApprove,
}) => {
  const { data } = useRBNTokenAccount();
  const rbnBalance = useTokenBalance("rbn") || BigNumber.from(0);

  return (
    <>
      <BaseModalContentColumn marginTop={48}>
        <Logo width={64} />
      </BaseModalContentColumn>
      <BaseModalContentColumn>
        <Title fontSize={22} lineHeight={28}>
          APPROVE L2DAO
        </Title>
      </BaseModalContentColumn>
      <BaseModalContentColumn marginTop={16}>
        <SecondaryText
          fontSize={16}
          lineHeight={24}
          fontWeight={400}
          className="text-center"
        >
          Before you continue, Layer2DAO first needs your permission to lock your
          L2DAO
        </SecondaryText>
      </BaseModalContentColumn>
      <BaseModalContentColumn marginTop={40}>
        <ActionButton
          className="py-3"
          color={colors.red}
          onClick={onApprove}
          disabled={!rbnBalance || rbnBalance.isZero()}
        >
          APPROVE L2DAO
        </ActionButton>
      </BaseModalContentColumn>
      <BaseModalContentColumn>
        <SecondaryText>
          Wallet Balance:{" "}
          {formatBigNumber(rbnBalance, 18)} L2DAO
        </SecondaryText>
      </BaseModalContentColumn>
    </>
  );
};

export default StakingModalApprove;
