import React from "react";
import styled from "styled-components";

import {
  BaseUnderlineLink,
  BaseModalContentColumn,
  Title,
  PrimaryText,
} from "shared/lib/designSystem";
import PendingTransactionLoader from "shared/lib/components/Common/PendingTransactionLoader";
import { getEtherscanURI } from "shared/lib/constants/constants";
import { useWeb3React } from "@web3-react/core";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const FloatingBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

interface ModalTransactionContentProps {
  title: string;
  txhash?: string;
  mode?: string;
}

const ModalTransactionContent: React.FC<ModalTransactionContentProps> = ({
  title,
  txhash,
  mode,
}) => {
  const { chainId } = useWeb3React();

  return (
    <>
      <BaseModalContentColumn marginTop={8}>
        <Title lineHeight={24}>{title}</Title>
      </BaseModalContentColumn>
      <FloatingBoxContainer>
        <PendingTransactionLoader active={Boolean(txhash)} />
      </FloatingBoxContainer>
      {Boolean(txhash) && (
        <BaseModalContentColumn marginTop="auto">
          {chainId !== undefined && (
            <BaseUnderlineLink
              to={`${getEtherscanURI(chainId)}/tx/${txhash}`}
              target="_blank"
              rel="noreferrer noopener"
              className="d-flex"
            >
              <PrimaryText className="mb-2">View on Arbiscan</PrimaryText>
            </BaseUnderlineLink>
          )}
          {mode == "claim-revenue" && (
        <BaseModalContentColumn marginTop="auto">
            <BaseUnderlineLink
              to={`https://twitter.com/intent/tweet?text=I%20just%20claimed%20${cookies.get('vaultRevenue')}%20ETH%20as%20my%20weekly%20revenue%20share%20from%20@TheLayer2DAO%21&hashtags=L2%20%23L222%20%23Arbitrum%20%24L2DAO%20%24xL2DAO`}
              target="_blank"
              rel="noreferrer noopener"
              className="d-flex"
            >
              <Title className="mb-2">Tweet Your DAO Revenue Share!</Title>
            </BaseUnderlineLink>
          </BaseModalContentColumn>
          )}
          </BaseModalContentColumn>
      )}
    </>
  );
};

export default ModalTransactionContent;
