import React, { useMemo } from "react";
import styled from "styled-components";

import {
  BaseModalContentColumn,
  BaseModalWarning,
  PrimaryText,
  SecondaryText,
  Subtitle,
  Title,
} from "shared/lib/designSystem";
import colors from "shared/lib/designSystem/colors";
import { UnstakeIcon } from "shared/lib/assets/icons/icons";
import moment from "moment";
import { formatBigNumber } from "shared/lib/utils/math";
import { BigNumber } from "@ethersproject/bignumber";
import { ActionButton } from "shared/lib/components/Common/buttons";
import {
  calculateEarlyUnlockPenalty,
  calculateEarlyUnlockPenaltyPercentage,
} from "shared/lib/utils/governanceMath";
import TooltipExplanation from "shared/lib/components/Common/TooltipExplanation";
import HelpInfo from "shared/lib/components/Common/HelpInfo";
import useTokenLocked from "shared/lib/hooks/useTokenLocked";
import useTokenLockEnd from "shared/lib/hooks/useTokenLockEnd";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: ${colors.red}1F;
  border-radius: 100px;
`;

interface UnstakingModalPreviewProps {
  onUnstake: () => void;
}

const UnstakingModalPreview: React.FC<UnstakingModalPreviewProps> = ({
  onUnstake,
}) => {
  const tokenLocked = useTokenLocked() || BigNumber.from(0);
  const tokenLockEnd = useTokenLockEnd() || BigNumber.from(0);
  const tokenLockEndTimestamp = tokenLockEnd.toNumber();

  const lockupAmountDisplay = useMemo(() => {
    return `${
      formatBigNumber(tokenLocked, 18, 2)
    } L2DAO`;
  }, [tokenLocked]);

  const lockupExpiryDisplay = useMemo(() => {
    return moment.unix(tokenLockEndTimestamp).format("MMM, Do YYYY");
  }, [tokenLockEndTimestamp]);

  const earlyUnlockDuration = useMemo(() => {
    const momentNow = moment();
    const expiryMoment = moment.unix(tokenLockEnd.toNumber());

    if (expiryMoment && momentNow.isBefore(expiryMoment)) {
      return moment.duration(expiryMoment.diff(momentNow));
    }
  }, [tokenLockEnd]);

  const earlyUnlockPenaltyPercentageDisplay = useMemo(() => {
    if (earlyUnlockDuration) {
      return `${(
        calculateEarlyUnlockPenaltyPercentage(earlyUnlockDuration) * 100
      ).toFixed(2)}%`;
    }
  }, [earlyUnlockDuration]);

  const earlyUnlockPenaltyAmountDisplay = useMemo(() => {
    if (tokenLocked && earlyUnlockDuration) {
      const penalty = calculateEarlyUnlockPenalty(
        tokenLocked,
        earlyUnlockDuration
      );
      return formatBigNumber(penalty);
    }
  }, [earlyUnlockDuration, tokenLocked]);

  const canUnstake = useMemo(() => {
    const momentNow = moment();
    const expiryMoment = moment.unix(tokenLockEnd.toNumber());
    return expiryMoment && momentNow.isAfter(expiryMoment);
  }, [tokenLockEnd]);

  return (
    <>
      <BaseModalContentColumn>
        <LogoContainer>
          <UnstakeIcon size="32px" color={colors.red} />
        </LogoContainer>
      </BaseModalContentColumn>
      <BaseModalContentColumn marginTop={16}>
        <Title fontSize={22} lineHeight={28}>
          Unlock Your L2DAO
        </Title>
      </BaseModalContentColumn>
      <BaseModalContentColumn>
        <div className="d-flex w-100 align-items-center">
          <SecondaryText lineHeight={24}>L2DAO Locked</SecondaryText>
          <TooltipExplanation
            title="L2DAO LOCKED"
            explanation="The amount of locked L2DAO that you previously locked."
            renderContent={({ ref, ...triggerHandler }) => (
              <HelpInfo containerRef={ref} {...triggerHandler}>
                i
              </HelpInfo>
            )}
          />
          <Subtitle
            fontSize={14}
            lineHeight={24}
            letterSpacing={1}
            className="ml-auto"
          >
            {lockupAmountDisplay}
          </Subtitle>
        </div>
      </BaseModalContentColumn>
      <BaseModalContentColumn>
        <div className="d-flex w-100 justify-content-between">
          <SecondaryText lineHeight={24}>Lockup Expiry</SecondaryText>
          <Subtitle fontSize={14} lineHeight={24} letterSpacing={1}>
            {lockupExpiryDisplay}
          </Subtitle>
        </div>
      </BaseModalContentColumn>
      <BaseModalContentColumn marginTop="auto" className="mb-2">
        <ActionButton
          onClick={onUnstake}
          className="py-3"
          color={colors.red}
          disabled={!canUnstake}
        >
          {earlyUnlockDuration ? "LOCKUP NOT EXPIRED" : "CONFIRM UNLOCK"}
        </ActionButton>
      </BaseModalContentColumn>
      {earlyUnlockPenaltyAmountDisplay && (
        <BaseModalWarning color={colors.green} className="mb-2" marginTop={16}>
          <PrimaryText
            fontSize={14}
            lineHeight={20}
            color={colors.green}
            fontWeight={400}
            className="text-center"
          >
            IMPORTANT: You cannot unlock your L2DAO until the lockup period has expired.
          </PrimaryText>
        </BaseModalWarning>
      )}
    </>
  );
};

export default UnstakingModalPreview;
