import React, { useMemo } from "react";
import styled from "styled-components";
import currency from "currency.js";
import { Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import { BigNumber } from "ethers";
import { formatUnits } from "ethers/lib/utils";

import { useRBNToken } from "shared/lib/hooks/useRBNTokenSubgraph";
import ProgressBar from "shared/lib/components/Deposit/ProgressBar";
import { ExternalIcon } from "shared/lib/assets/icons/icons";
import {
  BaseUnderlineLink,
  PrimaryText,
  SecondaryText,
  Title,
} from "shared/lib/designSystem";
import colors from "shared/lib/designSystem/colors";
import theme from "shared/lib/designSystem/theme";
import useAssetPrice, { useAssetInfo } from "shared/lib/hooks/useAssetPrice";
import useLoadingText from "shared/lib/hooks/useLoadingText";
import useScreenSize from "shared/lib/hooks/useScreenSize";
import sizes from "shared/lib/designSystem/sizes";
import { formatAmount } from "shared/lib/utils/math";
import useTokenLocked from "shared/lib/hooks/useTokenLocked";
import useTokenTotalLocked from "shared/lib/hooks/useTokenTotalLocked";
import useXTokenTotal from "shared/lib/hooks/useXTokenTotal";

const Content = styled(Row)`
  z-index: 1;
`;

const KPICard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  background: #ffffff0a;
  backdrop-filter: blur(40px);
  border-radius: ${theme.border.radius};

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const FloatingBackgroundContainer = styled.div<{ backgroundColor?: string }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  ${(props) =>
    props.backgroundColor ? `background: ${props.backgroundColor};` : ""};
`;

const OverviewKPI = () => {
  const { width, video } = useScreenSize();
  const { data: rbnToken, loading: rbnTokenAccountLoading } = useRBNToken();
  const { info, loading: assetInfoLoading } = useAssetInfo("RBN");
  const circulating_supply = 343000000;
  const { price: RBNPrice, loading: assetPriceLoading } = useAssetPrice({
    asset: "RBN",
  });
  const tokenTotalLocked = useTokenTotalLocked() || BigNumber.from(0);
  const xTokenTotal = useXTokenTotal() || BigNumber.from(0);

  const loadingText = useLoadingText();

  const percentageStaked = useMemo(() => {
    if (circulating_supply) {
      const totalStakedNumber = parseFloat(
        formatUnits(tokenTotalLocked || BigNumber.from(0), 18)
      );
      return (totalStakedNumber / circulating_supply) * 100;
    }
    return 0;
  }, [info, tokenTotalLocked]);

  return (
    <>
      <Content className="justify-content-center">
        {/* Left text */}
        <Col
          xs={12}
          lg={5}
          className="d-flex flex-column justify-content-center"
        >
          <Title
            fontSize={width >= sizes.lg ? 56 : 48}
            lineHeight={width >= sizes.lg ? 60 : 56}
            letterSpacing={1}
            className={width < sizes.lg ? "text-center" : ""}
          >
            LAYER2DAO REVENUE SHARING
          </Title>
          <SecondaryText
            fontSize={16}
            lineHeight={24}
            className={`mt-4 ${width < sizes.lg ? "text-center px-4" : ""}`}
          >
            The Layer2DAO protocol is owned and governed by L2DAO holders via
            fully on-chain governance
          </SecondaryText>
          <BaseUnderlineLink
            to="https://docs.layer2dao.org/tokenomics"
            target="_blank"
            rel="noreferrer noopener"
            className={`d-flex mt-4 ${width < sizes.lg ? "mx-auto" : ""}`}
          >
            <PrimaryText fontSize={16} lineHeight={24} fontWeight={400}>
              Learn More
            </PrimaryText>
            <ExternalIcon className="ml-2" color={colors.primaryText} />
          </BaseUnderlineLink>
        </Col>

        {/* Right KPI */}
        <Col
          lg={{ span: 5, offset: 1 }}
          className="d-none d-lg-flex flex-column justify-content-center"
        >
          <KPICard>
              <SecondaryText fontSize={12} lineHeight={16}>
                Total xL2DAO
              </SecondaryText>
              <Title fontSize={16} lineHeight={24} letterSpacing={1} className="mt-2">
                {formatAmount(
                      Number(
                        formatUnits(xTokenTotal || BigNumber.from(0))
                      ),
                      true
                    )}
              </Title>
          </KPICard>
          <KPICard>
            <div className="d-flex flex-row align-items-center justify-content-between mb-2">
              <SecondaryText fontSize={12} lineHeight={16}>
                Total Locked L2DAO
              </SecondaryText>
              <Title fontSize={16} lineHeight={24} letterSpacing={1}>
                {formatAmount(
                      Number(
                        formatUnits(tokenTotalLocked || BigNumber.from(0))
                      ),
                      true
                    )}
              </Title>
            </div>
            <ProgressBar
              config={{ height: 4, extraClassNames: "my-2", radius: 2 }}
              percent={percentageStaked}
              color={colors.green}
            />
            <div className="d-flex flex-row align-items-center justify-content-between mt-2">
              <SecondaryText fontSize={12} lineHeight={16}>
                L2DAO Floating Supply
              </SecondaryText>
              <Title fontSize={16} lineHeight={24} letterSpacing={1}>
                {formatAmount(circulating_supply, true)}
              </Title>
            </div>
          </KPICard>
            <KPICard>
            <SecondaryText fontSize={12} lineHeight={16}>
              L2DAO Price
            </SecondaryText>
            <Title
              fontSize={16}
              lineHeight={24}
              letterSpacing={1}
              className="mt-2"
            >
              {assetPriceLoading ? loadingText : `$${RBNPrice.toFixed(4)}`}
            </Title>
          </KPICard>
        </Col>
      </Content>
      <FloatingBackgroundContainer>
        <ReactPlayer
          key="video-player"
          url="https://player.vimeo.com/video/747466041?background=1"
          playing={true}
          width={video.width}
          height={video.height}
          style={{
            minWidth: video.width,
            minHeight: video.height,
          }}
          muted
          loop
        />
      </FloatingBackgroundContainer>
      <FloatingBackgroundContainer backgroundColor="#000000CC" />
    </>
  );
};

export default OverviewKPI;
